
export function encodeString(input) {
    let encoded = '';
    for (let i = 0; i < input.length; i++) {
      encoded += String.fromCharCode(input.charCodeAt(i) + 1);
    }
    return encoded;
  }
  
  export function decodeString(encoded) {
    let decoded = '';
    for (let i = 0; i < encoded.length; i++) {
      decoded += String.fromCharCode(encoded.charCodeAt(i) - 1);
    }
    return decoded;
  }