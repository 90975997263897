import React, { useEffect, useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { io } from 'socket.io-client';
import './App.css';
import EnterEmail from './components/EnterEmail';
import JoinScanner from './components/joinscanner';
import Qrcodezone from './components/Qrcodezone';
import WelcomeScreen from './components/WelcomeScreen';
import NotFound from './components/NotFound';
import ChatPage from './components/ChatPage';
import { TranslationProvider } from './utils/TranslationContext';
import LanguageSwitcher from './utils/LanguageSwitcher';

const socket= io(process.env.REACT_APP_SOCKET_URL);

console.log("Connection Checked---->",socket)

const weburl = process.env.REACT_APP_WEB_URL;
const apiurl = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_VERSION

console.log("weburl",weburl);
console.log("apiurl",apiurl);
console.log("version",version)

function App() {
  const [username, setUsername] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (username, roomName) => {
    setIsLoggedIn(true);
    setUsername(username);
  };



  return (
    <TranslationProvider>
      <HashRouter>
        {/* <LanguageSwitcher /> */}
        <Routes>
          <Route path="/" element={<EnterEmail />} />
          <Route path="/chat" element={<ChatPage username={username} socket={socket} />} />
          <Route path="/letchat" element={<WelcomeScreen onLogin={handleLogin} socket={socket} />} />
          <Route path="/joinscan" element={<JoinScanner socket={socket} />} />
          <Route path="/welcome" element={<Qrcodezone socket={socket} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </TranslationProvider>
  );
}

export default App;


