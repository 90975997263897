import React, { useState, useEffect, useRef } from 'react';
import './VideoAdBanner.css';

const VideoAdBanner = () => {
  const [showAd, setShowAd] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoname, setVideoname] = useState('');
  const [videoDuration, setVideoDuration] = useState(0);
  const [skipCountdown, setSkipCountdown] = useState(5);
  const [isUnskippable, setIsUnskippable] = useState(false);
  const videoRef = useRef(null);
  const countdownIntervalRef = useRef(null);
  const autoSkipTimeoutRef = useRef(null);

  const adVideoSrcName = ['ad1.mp4', 'ad2.mp4', 'ad3.mp4'];

  const getRandomVideo = () => {
    const randomIndex = Math.floor(Math.random() * adVideoSrcName.length);
    return adVideoSrcName[randomIndex];
  };

  const setupAd = () => {
    setVideoname(getRandomVideo());
    setShowAd(true);
    setVideoEnded(false);
    setSkipCountdown(5);

    // Determine if unskippable based on even/odd
    const randomNumber = Math.floor(Math.random() * 100);
    const newIsUnskippable = randomNumber % 2 === 0;
    setIsUnskippable(newIsUnskippable);

    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    if (autoSkipTimeoutRef.current) {
      clearTimeout(autoSkipTimeoutRef.current);
    }

    if (!newIsUnskippable) {
      countdownIntervalRef.current = setInterval(() => {
        setSkipCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(countdownIntervalRef.current);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    } else {
      // Auto-skip after 10 seconds for unskippable ads
      autoSkipTimeoutRef.current = setTimeout(() => {
        setVideoEnded(true); // Mark video as ended to allow skipping
        handleSkip();
      }, 10000);
    }
  };

  const scheduleReappear = () => {
    setTimeout(() => {
      setupAd();
    }, 10000);
  };

  useEffect(() => {
    setupAd();
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
      if (autoSkipTimeoutRef.current) {
        clearTimeout(autoSkipTimeoutRef.current);
      }
    };
  }, []);

  const handleSkip = () => {
    setShowAd(false);
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    if (autoSkipTimeoutRef.current) {
      clearTimeout(autoSkipTimeoutRef.current);
    }
    scheduleReappear();
  };

  const handleVideoEnd = () => {
    setVideoEnded(true);
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    if (autoSkipTimeoutRef.current) {
      clearTimeout(autoSkipTimeoutRef.current);
    }
    // Auto-skip the ad when it ends
    handleSkip();
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(Math.ceil(videoRef.current.duration));
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    showAd && (
      <div className="video-ad-banner">
        <video
          ref={videoRef}
          src="https://watchparty.tpvai.com/b/uploads/ad3.mp4"
          controls={false}
          autoPlay
          onEnded={handleVideoEnd}
          onLoadedMetadata={handleLoadedMetadata}
          muted
        ></video>
        {isUnskippable ? (
          <button
            className={`skip-button ${!videoEnded ? 'disabled' : ''}`}
            onClick={handleSkip}
            disabled={!videoEnded}
          >
            {!videoEnded
              ? `Unskippable Ads`
              : 'Skip Ad'}
          </button>
        ) : (
          <button
            className={`skip-button ${skipCountdown > 0 ? 'disabled' : ''}`}
            onClick={handleSkip}
            disabled={skipCountdown > 0}
          >
            {skipCountdown > 0 ? `Skip Ad in ${skipCountdown}` : 'Skip Ad'}
          </button>
        )}
      </div>

      // <>
      // </>
    )
  );
};

export default VideoAdBanner;
