// JoinScanner.js
import React, { useEffect } from "react";
import { BrowserQRCodeReader } from "@zxing/library";
import { useTranslation } from '../utils/TranslationContext'; 
import {decodeString} from '../utils/encryptionUtil';
import { getParameterValue } from '../utils/mainUtil';
export default function JoinScanner({ socket }) {
  const { t } = useTranslation();
  console.log("Current URL:", window.location.href);
  let tokenhost;
  let tokenjoinee;
  let chaturl;
  let channelId = null;
  let programInfo = null;
  const urlwithtoken = window.location.href;
  const urltoken = /token=([a-zA-Z0-9]+)/;
  const match = urltoken.exec(urlwithtoken);
  console.log("sassssssss", match);
  if (match && match[1]) {
    tokenhost = match[1];
    console.log("Extracted Token from url of host:", tokenhost);
  } else {
    console.log("Token not found in the log text.");
  }

  const joinedurllll = /joinedurl=([a-zA-Z0-9:/]+)/;
  const match1 = joinedurllll.exec(urlwithtoken);
  chaturl = match1[1];

const startIndex = urlwithtoken.indexOf('channelId=') + 'channelId='.length;
// const startIndexProgram = urlwithtoken.indexOf('programInfo=') + 'programInfo='.length;
const encodedPart = urlwithtoken.substring(startIndex);

// Decoding the URL
const decodedPart = decodeURIComponent(encodedPart);



console.log("match13",decodedPart)
try {
  if (decodedPart) {
    channelId = decodeString(decodedPart);
    programInfo=getParameterValue('programInfo')
  } else {
    console.error("Channel ID not found in the URL.");
    channelId = null;
    programInfo=null
  }
} catch (error) {
  console.error("Error parsing channelId:", error.message);
  channelId = null;
  programInfo=null
}


  useEffect(() => {
    const codeReader = new BrowserQRCodeReader();
    const previewElem = document.querySelector("#WatchpartyUjjwal > video");

    async function startScanner() {
      try {
        const controls1 = await codeReader.decodeOnceFromVideoDevice(
          undefined,
          previewElem
        );

        console.log("hellllllll", controls1);
        alert(controls1.text);
        const tokenPattern = /token=([a-zA-Z0-9]+)/;
        const match = tokenPattern.exec(controls1.text);
        if (match && match[1]) {
          tokenjoinee = match[1];
          console.log("Extracted Token:", tokenjoinee);
        } else {
          console.log("Token not found in the log text.");
        }
        const mainaddedtoken = {
          host: tokenhost,
          joins: tokenjoinee,
          conversions: chaturl,
          channelId: channelId,
          GetprogramInfo: programInfo

        };
        socket.emit("TVconnected", { data: mainaddedtoken });
      } catch (error) {
        console.log(error);
      }
    }

    startScanner();

    return () => {
      codeReader.reset();
    };
  }, []);
  return (
    <>
      <div className="container">
        <div className="row min-vh-100 justify-content-center">
          <div className="col-12 text-center"></div>
          <div
            className="col-md-8 col-lg-6 col-xl-4 text-center"
            style={{ marginTop: "-5%", marginBottom: "3%" }}
          >
            <div className="card col-xl-4">
              <h5>{t('Welcome to WatchRoom')}</h5>
              <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                <p>{t('Follow these steps')}:</p>
                <ol style={{ textAlign: "justify" }}>
                  <li>{t('Go to your TV')}.</li>
                  <li>{t('Launch the Watch Room app')}.</li>
                  <li>{t('Scan the QR code')}.</li>
                </ol>
              </div>

              <div className="card-body text-center">
                <div id="WatchpartyUjjwal">
                  <video width="300" height="300"></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
