import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../utils/TranslationContext'; 
function WelcomeScreen({ onLogin, socket }) {
  const [state, setState] = useState(false);
  const [pInfo, setpInfo] = useState("");
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [GetChannelId, setChannelId] = useState("");
  const [GetprogramInfo, setGetprogramInfo] = useState();
  const [GetHostId, setGetChannelId] = useState();
 

  const { t } = useTranslation();
  const loginButtonRef = useRef(null);

  const history = useNavigate();

console.log("nstate-->",state)


  useEffect(() => {
    socket.on("state", (newState) => {
      setState(newState);
    });

    socket.on("GetprogramInfo", (info) => {
      setpInfo(info);
    });

    const storedRoom = localStorage.getItem("room");
    const storedEmail = localStorage.getItem("email");
    const storedChannelId = localStorage.getItem("ChannelId");
    const storedProgramInfo = localStorage.getItem("ProgramInfo");
    const HostId = localStorage.getItem("UserRoomId");

    if (HostId == storedRoom) {
      try {
            console.log("_________________>Host Id")
            setGetChannelId(true)
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error.message);
      }
    } else {
      console.log("________________>Client Id");
      setGetChannelId(false)
    }


    if (storedChannelId) {
      try {
        setChannelId(storedChannelId)
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error.message);
      }
    } else {
      console.log("No data found in local storage.");
    }

    if (storedProgramInfo) {
      try {
        setGetprogramInfo(storedProgramInfo)
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error.message);
      }
    } else {
      console.log("No data found in local storage.");
    }


    if (storedEmail && storedRoom) {
      setUsername(storedEmail);
      setRoomName(storedRoom);

      const handleKeyPress = (event) => {
        console.log("Hit Enter");
        if (event.key === "Enter") {
          loginButtonRef.current.click();
          console.log("Hit Enter");
        }
      };

      document.addEventListener("keypress", handleKeyPress);

      return () => {
        document.removeEventListener("keypress", handleKeyPress);
      };
    }
  }, []);


  console.log("Host Checked---->",GetHostId)




  console.log("GetChannelId---usestate-->",GetChannelId)
  console.log("GetChannelId---typeof-->",typeof GetChannelId )

  console.log("GetprogramInfo------>",pInfo)
  console.log("GetprogramInfo------>",GetprogramInfo)



  const newroomid = localStorage.getItem("UserRoomId");
  console.log("checkhost-RoomId->",roomName,"UserRoomId->",newroomid)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (socket) {
      socket.emit("state", false);
    }
    onLogin(username, roomName);
    socket.emit("join-room", { roomName: roomName, nickName: username });
    console.log("username, roomName", username, roomName);
    localStorage.setItem("roomid", roomName);
    history("/chat");
  };

  const create = () => {
    if (socket) {
      socket.emit("state", true);
    }
    socket.emit("create-new-room", { roomName: roomName, nickName: username });
    console.log("socket click");
    onLogin();
    localStorage.setItem("roomid", roomName);

    history("/chat");
  };


  function detectPlatform() {
    const userAgent = navigator.userAgent.toLowerCase();
  
    const isAndroid = /android/.test(userAgent);
    const isLinux = /linux/.test(userAgent) && !/android/.test(userAgent);
    const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
  
    if (isAndroid) {
      return 'Android';
    } else if (isLinux) {
      return 'Linux';
    } else if (isBrowser) {
      return 'Browser';
    } else {
      return 'Unknown platform';
    }
  }

  function TriggerChannelLinux(jsonParse) {
    console.log("Calling parent function with payload");
    window.parent.postMessage({ type: 'tuneToChannel', channelId: jsonParse }, '*');
}

  const jsonString = JSON.stringify(GetChannelId);
  console.log("___________________________________________final_________jsonString->",GetChannelId)
  
  const handleButtonClick = (e) => {
    localStorage.setItem("alreadyJoined", "true");
    e.preventDefault();
    if (socket) {
      if (state) {
        socket.emit("state", false);
        onLogin(username, roomName);
        socket.emit("join-room", { roomName: roomName, nickName: username });
      } else {
        socket.emit("state", true);
        socket.emit("create-new-room", {
          roomName: roomName,
          nickName: username,
        });
        console.log("socket click");
        onLogin();
      }
      localStorage.setItem("roomid", roomName);
      history("/chat");

      const platform = detectPlatform();
      if (platform === 'Android') {

        } else if (platform === 'Linux') {
          try{
            if(window.JavaFun.getPlatformEnvironment() == "android"){ 
              // window.JavaFun.triggerChannel("content://android.media.tv/channel/61")
              console.log("__________________________________android_________final_________jsonString->",jsonString)
              if(GetHostId === false){
                const jsonParse = JSON.parse(GetChannelId);
                window.JavaFun.triggerChannel(jsonParse)
              }
            }else{
              console.log("Trigger Linux Function");
            }
          }
          catch(e) {
            console.log("linux error", e)
            try {
              if(GetHostId === false){
                const jsonParse = JSON.parse(GetChannelId);
                TriggerChannelLinux(jsonParse);
              }else{
                console.log("Trigger Linux Function");
              }
              
            } catch (jsonError) {
              console.log("Error parsing JSON for Linux:", jsonError);
            }
        
            }

          console.log("Running on Linux");
        } else if (platform === 'Browser') {
          console.log("Running in a browser");
        } else {
          console.log("Unknown platform");
        }

    }
  };

  return (
    <>
      <div className="container">
        <div className="row min-vh-100 justify-content-center">
          <div className="col-12 text-center"></div>
          <div
            className="col-md-8 col-lg-6 col-xl-4 text-center"
            style={{ marginTop: "-5%", marginBottom: "3%" }}
          >
             {/* <h5>ProgramInfoFromAndroidTv---{GetprogramInfo}</h5>  */}
            <div className="card">
              <div className="card-body text-center">
                <h6>{t('Press the below Remote Controller Keys')}</h6>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/sticker1.png"
                      width="30"
                      height="30"
                    />
                    1
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/sticker2.png"
                      width="30"
                      height="30"
                    />
                    2
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/sticker3.png"
                      width="30"
                      height="30"
                    />
                    3
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/sticker4.png"
                      width="30"
                      height="30"
                    />
                    4
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/sticker5.png"
                      width="30"
                      height="30"
                    />
                    5
                  </p>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/emoji6.gif"
                      width="30"
                      height="30"
                    />
                    6
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/emoji7.gif"
                      width="30"
                      height="30"
                    />
                    7
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/emoji8.gif"
                      width="30"
                      height="30"
                    />
                    8
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/emoji9.gif"
                      width="30"
                      height="30"
                    />
                    9
                  </p>
                  <p>
                    <img
                      src="https://watchparty.tpvai.com/b/uploads/emoji0.gif"
                      width="30"
                      height="30"
                    />
                    10
                  </p>
                </div>

                <button
                  onClick={(e) => handleButtonClick(e)}
                  ref={loginButtonRef}
                  color="primary"
                  variant="contained"
                  className="button-24"
                >
                  {state ? t('Join') : t('Create')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WelcomeScreen;
