import React, { useState, useEffect, useRef, useCallback } from "react";
import '../App.css';
import axios from "axios";
import Swal from 'sweetalert2';
import AdBanner from './ads/AdBanner'
import Page2 from './ai components/Page2'
import Page3 from './ai components/Page3'
import Page4 from './ai components/Page4'
import Page5 from './ai components/Page5'

const styles = {
  textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
  textFieldContainer: { flex: 1, marginRight: 12 },
  gridItem: { paddingTop: 12, paddingBottom: 12 },
  gridItemChatList: { overflow: "auto", height: "74vh", width: "-webkit-fill-available" },
  gridItemMessage: { marginTop: 12, marginBottom: 12 },
  sendButton: { backgroundColor: "transparent" },
  sendIcon: { color: "white" },
  mainGrid: { paddingTop: 100, borderWidth: 1 },
  buttonContainer: {
    position: 'fixed',
    bottom: '8px',
    left: 0,
    right: 0,
    display: 'grid',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  buttonT: {
    background: 'linear-gradient(to right, #00c9ff, #928ce5)',
    border: 'none',
    borderRadius: '25px',
    color: 'white',
    fontSize: '18px',
    padding: '6px 26px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },
  span: {
    cursor: 'pointer',
  },
};

function extractSpecificUsername(email) {
  var usernameRegex = /\/([^@\/]+)/;
  var usernameMatch = email.match(usernameRegex);
  return usernameMatch ? usernameMatch[1] : null;
}

function extractLastPathPart(url) {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
}

function getStringBeforeAtSymbol(inputString) {
  const atIndex = inputString.indexOf('@');
  return atIndex === -1 ? inputString : inputString.substring(0, atIndex);
}

const HARDCODED_EMOJIS = [
  {"id":1,"url":"/images/GENRE_CHILDREN/sticker (1).png","paid":false,"category":"GENRE_CHILDREN","type":"png"},
  {"id":2,"url":"/images/GENRE_CHILDREN/sticker (2).png","paid":true,"category":"GENRE_CHILDREN","type":"png"},
  {"id":3,"url":"/images/GENRE_CHILDREN/sticker (3).png","paid":false,"category":"GENRE_CHILDREN","type":"png"},
  {"id":4,"url":"/images/GENRE_CHILDREN/sticker (4).png","paid":true,"category":"GENRE_CHILDREN","type":"png"},
  {"id":5,"url":"/images/GENRE_CHILDREN/sticker (5).png","paid":false,"category":"GENRE_CHILDREN","type":"png"},
  {"id":6,"url":"/images/GENRE_CHILDREN/sticker (6).png","paid":true,"category":"GENRE_CHILDREN","type":"png"},
  {"id":7,"url":"/images/GENRE_CHILDREN/sticker (7).png","paid":false,"category":"GENRE_CHILDREN","type":"png"},
  {"id":8,"url":"/images/GENRE_CHILDREN/sticker (8).png","paid":true,"category":"GENRE_CHILDREN","type":"png"},
  {"id":9,"url":"/images/GENRE_CHILDREN/sticker (9).png","paid":true,"category":"GENRE_CHILDREN","type":"png"},
  {"id":10,"url":"/images/GENRE_CHILDREN/sticker (10).png","paid":true,"category":"GENRE_CHILDREN","type":"png"},
  {"id":11,"url":"/images/DEFAULT/emoji0.gif","paid":false,"category":"Default","type":"gif"},
  {"id":12,"url":"/images/DEFAULT/emoji6.gif","paid":false,"category":"Default","type":"gif"},
  {"id":13,"url":"/images/DEFAULT/emoji7.gif","paid":true,"category":"Default","type":"gif"},
  {"id":14,"url":"/images/DEFAULT/emoji8.gif","paid":false,"category":"Default","type":"gif"},
  {"id":15,"url":"/images/DEFAULT/emoji9.gif","paid":false,"category":"Default","type":"gif"}
];

const ChatScreen = ({ username, socket }) => {
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [usernameid, setUsernameid] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState(null);
  const [floatingEmojis, setFloatingEmojis] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [roomname, setRoomname] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [additionalEmojis, setAdditionalEmojis] = useState([]);
  const [isPaymentDone, setIsPaymentDone] = useState(false);

  const [ProgramInfo, setProgramInfo] = useState(null);


  //start
  const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
  const audioRefs = useRef([]);

//Voice Chat
  const [recording, setRecording] = useState(false);
  const [uploadedURL, setUploadedURL] = useState("https://watchparty.tpvai.com/b/uploads/audio/1729778052796.wav");
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const audioRef = useRef(null);


    //new changes for ui
    const [emojis, setEmojis] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [clickedImage, setClickedImage] = useState(null);

    //ai
    const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    const imagesPerPage = 6;
  
    const emojiRefs = useRef([]);


  const recordTimeoutRef = useRef(null)


  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
  
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunks.current.push(event.data);
    };
  
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
      console.log("audioBlob-->", audioBlob);
      setAudioBlob(audioBlob);
      uploadAudio(audioBlob); // Upload after recording is stopped
    };
  
    mediaRecorderRef.current.start();
    setRecording(true);
  
    // Automatically stop recording after 10 seconds
    recordTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, 10000); // 10000 ms = 10 seconds
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    audioChunks.current = []; // Clear the chunks after recording
  
    // Clear the timeout if the recording stops before 10 seconds
    if (recordTimeoutRef.current) {
      clearTimeout(recordTimeoutRef.current);
    }
  };
  

  // Upload Audio
  const uploadAudio = async (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");

    try {
      const response = await axios.post("https://watchparty.tpvai.com/b/audioupload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setUploadedURL(response.data.uploadedURL); // Use the returned URL to play the audio
      sendMessage(response.data.uploadedURL)
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  
  // Adding play and stop audio functionalities
  const playAudio = (index) => {


    if (window.JavaFun) {
      const audiourl = uploadedURL
      const stateaudio = true
      window.JavaFun.audioPlayStatus(audiourl,stateaudio);
          // window.JavaFun.audioPlayStatus(true);
    }
  };

  const stopAudio = (index) => {
    if (audioRefs.current[index]) {
      audioRefs.current[index].pause();
      audioRefs.current[index].currentTime = 0;
      setCurrentAudioIndex(null); // Reset current audio index
      if (window.JavaFun) {
        window.JavaFun.audioPlayStatus(false);
    }
    }
  };


  //Voice Chat

  const scrollDiv = useRef(null);
  const Button1Ref = useRef(null);
  const Button2Ref = useRef(null);
  const Button3Ref = useRef(null);
  const Button4Ref = useRef(null);
  const Button5Ref = useRef(null);
  const Button6Ref = useRef(null);
  const Button7Ref = useRef(null);
  const Button8Ref = useRef(null);
  const Button9Ref = useRef(null);
  const Button10Ref = useRef(null);

  const CurrentUser = localStorage.getItem("email");

  useEffect(() => {
    const storedEmail = localStorage.getItem('userId');
    const username = storedEmail.split("@")[0];
    setUsernameid(username);

    const roomid = localStorage.getItem('roomid');
    setRoomname(roomid);


    //payment start
    const paymentStatus = localStorage.getItem('paymentStatus');
    if (paymentStatus === 'true') {
      setIsPaymentDone(true);
    }
    //payment end


    // api call
const fetchEmojis = async () => {
  try {
    setLoading(true);
    const storeProgramInfo = localStorage.getItem("ProgramInfo")
    let category = storeProgramInfo;
    // Remove any surrounding quotes from the category string, if present
    if (typeof category === 'string') {
      category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
    }

    console.log("sahu2new",category)
    setProgramInfo(storeProgramInfo)
    const response = await axios.get(
      `https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`
    );
    setEmojis(response.data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching emojis", error);
    setLoading(false);
  }
};
    fetchEmojis();
    
    const handleKeyPress = (e) => {
      if (e.key === "7") {
        setCurrentAiPage(1)
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };

  }, []);






  

    //new changes ui
    const handleNext = useCallback(() => {
      if (startIndex + imagesPerPage < emojis.length) {
        setStartIndex((prevIndex) => prevIndex + imagesPerPage);
      }
    }, [startIndex, emojis.length]);
  
    const handlePrevious = useCallback(() => {
      if (startIndex - imagesPerPage >= 0) {
        setStartIndex((prevIndex) => prevIndex - imagesPerPage);
      }
    }, [startIndex]);
  
    const handleImageClick = (index,emoji) => {
      setClickedImage(index + 1);
      console.log("emojis-new-audio-4",emoji.url)
      const url =  `https://watchparty.tpvai.com/b${emoji.url}`
      if (emoji.paid && !isPaymentDone) {
        handlePaymentConfirmation();
      } else {
        sendMessage(url)
      }
    };
  
  console.log("anu recording",recording)
    useEffect(() => {
      const handleKeyPress = (event) => {
        const key = event.key;
  
        if (key >= "1" && key <= "6") {
          const index = parseInt(key) - 1;
          if (emojiRefs.current[index]) {
            console.log("emojis-new-audio-2",clickedImage)
            emojiRefs.current[index].click();
          }
        // } else if (key === "ArrowRight") {
        } else if (key === "0") {
          handleNext();
        } 
        // else if (key === "9") {
        //   console.log("anu 9")
        //   recording ? stopRecording() : startRecording();
        // }
        else if (key === "8") {
          handlePrevious();
        }
      };
  
      window.addEventListener("keydown", handleKeyPress);
  
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [handleNext, handlePrevious]);
  

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "9") {
          if (recording) {
            stopRecording();
          } else {
            startRecording();
          }
        }
      };
  
      window.addEventListener("keydown", handleKeyDown);
  
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [recording]);


  
    console.log("emojis-new-audio-3",clickedImage)
  

  const currentEmojis = emojis.slice(startIndex, startIndex + imagesPerPage);


  const handlePaymentConfirmation = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Payment Confirmation',
      html: `
        <div style="text-align: center;">
          <label for="swal-input1">Amount: <strong>$30</strong></label>
        </div>
        <input id="swal-input1" class="swal2-input" placeholder="Enter your card number" type="text" 
               maxlength="16" pattern="\\d{16}" inputmode="numeric" style="margin-top: 15px;">
        <input id="swal-input3" class="swal2-input" placeholder="MM/YY" type="text" 
               pattern="(0[1-9]|1[0-2])\\d{2}" maxlength="4" style="margin-top: 15px;">
        <input id="swal-input2" class="swal2-input" placeholder="Enter CVV" type="text" 
               maxlength="3" pattern="\\d{3}" inputmode="numeric">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Pay',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        const cardNumber = document.getElementById('swal-input1').value;
        const expiryDate = document.getElementById('swal-input3').value;
        const cvv = document.getElementById('swal-input2').value;
  
        if (!cardNumber || !expiryDate || !cvv) {
          Swal.showValidationMessage('Please enter your card number, expiry date, and CVV');
          return false;
        }
        return { cardNumber, expiryDate, cvv };
      },
      customClass: {
        popup: 'custom-swal-popup',
      },
    });
  
    if (formValues) {
      // Simulate payment success after user submits details
      localStorage.setItem('paymentStatus', 'true');
      setIsPaymentDone(true);
    }
  };
  
  const showFloatingEmoji = (imageUrl) => {
    if (imageUrl.endsWith('.png')) return; //not flying stickers
    if (imageUrl.endsWith('.wav')) return;
    const screenWidth = window.innerWidth;
    const maxOffset = screenWidth / 4;
    const offset = Math.random() * maxOffset * (Math.random() < 0.5 ? -1 : 1);
    const container = document.createElement('div');
    container.className = 'floating-emoji-container';
    container.style.left = `calc(50% + ${offset}px)`;
    container.style.transform = 'translateX(-50%)';
    const floatingEmoji = document.createElement('img');
    floatingEmoji.className = 'floating-emoji';
    floatingEmoji.src = imageUrl;
    floatingEmoji.alt = 'Emoji';
    floatingEmoji.style.width = '60px';
    floatingEmoji.style.height = '60px';
    container.appendChild(floatingEmoji);
    document.body.appendChild(container);
    const expirationTime = Date.now() + 2000;
    setFloatingEmojis([...floatingEmojis, { container, expirationTime }]);

    const cardRect = document.querySelector('.card').getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const emojiRect = floatingEmoji.getBoundingClientRect();

    if (containerRect.bottom > cardRect.top) {
      const newTop = cardRect.top - emojiRect.height - 10;
      container.style.top = `${newTop}px`;
    }
  };

  // ai
  const handleGenerateImage = async (prompt) => {
    setIsLoading(true);
    try {
      const response = await fetch('https://watchparty.tpvai.com/b/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
      
      const data = await response.json();
      console.log("data-->",data.imageurl)
      setGeneratedImage(data.imageurl
); // Assuming the API returns { image: "imageUrl" }
    setCurrentAiPage(4); // Navigate to Page5 after successful generation
    } catch (error) {
      console.error('Error generating image:', error);
      alert('Failed to generate image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    const handleMessage = (message) => {
      const username = message.from;
      const isWav = message.message.endsWith('.wav');
  
      showFloatingEmoji(message.message);
      setMessages((prevMessages) => [...prevMessages, message]);
      console.log("anu play -1")
      // Automatically play audio if the message is a .wav file
      if (isWav) {
        console.log("anu play 0")
        setTimeout(() => {
          console.log("anu play 1")
          const newIndex = messages.length; // Index of the new message
          setCurrentAudioIndex(newIndex); // Store index to control the current audio
          // audioRefs.current[newIndex].play(); // Auto-play the new audio
          playAudio(newIndex);
        }, 100); // Delay to ensure audio ref is set
      }
    };
  
    socket.on("send-message-to-client", handleMessage);
  
    return () => {
      socket.off("send-message-to-client", handleMessage);
    };
  }, [messages, socket]);
  

  const sendMessage = (messageToSend) => {
    if (messageToSend) {
      socket.emit("receive-message-from-client", messageToSend);
      setMessageInput('');
    }
  };

  const [currentAiPage, setCurrentAiPage] = useState('main');

  const handleAiNext = () => {
    const currentPageNum = parseInt(currentAiPage);
    if (currentPageNum < 4) setCurrentAiPage(currentPageNum + 1);
  };

  const handleAiPrev = () => {
    const currentPageNum = parseInt(currentAiPage);
    if (currentPageNum > 1) setCurrentAiPage(currentPageNum - 1);
  };

  const renderAiPage = () => {
    switch(currentAiPage) {
      case 1:
        return (
          <div className="card">
            {/* <button onClick={() => setCurrentAiPage('main')} className="back-btn">Back Home</button>
            <h2>Page 1 Content</h2> */}
            <Page2 setCurrentAiPage={setCurrentAiPage}/>
            {/* Add your page 1 content here */}
          </div>
        );
      case 2:
        return (
          <div className="card">
            {/* <button onClick={() => setCurrentAiPage('main')} className="back-btn">Back Home</button>
            <h2>Page 2 Content</h2> */}
            <Page3 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage}/>
            {/* Add your page 2 content here */}
          </div>
        );
      case 3:
        return (
          <div className="card">
            <Page4 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage} />
            {/* Add your page 3 content here */}
          </div>
        );
      case 4:
        return (
          <div className="card">
          <Page5 setCurrentAiPage={setCurrentAiPage} generatedImage={generatedImage} isLoading={isLoading} sendMessage={sendMessage}/>
            {/* Add your page 4 content here */}
          </div>
        );
      default:
        return (
          <div className="card">
            <div className="container text-center mt-4" id="emoji-gallery" tabIndex="0" style={{ margin: 0, padding: 0 }}>
              {loading ? (
                <div className="spinner-border" role="status" style={{margin: '27px 82px'}}>
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>
                  <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
                    {currentEmojis.map((emoji, index) => (
                      <div
                        key={index}
                        className="col-4 d-flex flex-column align-items-center position-relative px-1" // Added px-1 for spacing
                        ref={(el) => (emojiRefs.current[index] = el)}
                        onClick={() => handleImageClick(index, emoji)}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {emoji.paid && (
                          <span style={{position: 'absolute', backgroundColor: '#dfb400', color: '#000', width: '21px', height: '21px', borderRadius: '50%', display: 'inline-block', textAlign: 'center', marginLeft:'47px'}}>
                            P
                          </span>
                        )}
                        <img
                          src={`https://watchparty.tpvai.com/b${emoji.url}`}
                          alt="emoji"
                          className="img-fluid"
                          style={{
                            width: "23px",
                            height: "23px",
                            objectFit: "cover",
                          }}
                        />
                        <span className="">{index + 1}</span>
                      </div>
                    ))}
                  </div>



                  <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
                    <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
                      <div style={{ cursor: "pointer" }} onClick={handlePrevious}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/608/608283.png"
                          alt="previous"
                          className="img-fluid"
                          style={{
                            width: "23px",
                            height: "23px",
                            objectFit: "cover",
                          }}
                        />
                        <p className="mt-2">8</p>
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
                      <div style={{ cursor: "pointer" }}>

                        <div 
                              tabIndex={0}  // Make the div focusable
                              onClick={recording ? stopRecording : startRecording} 
                              role="button"  // Add role for better accessibility
                              style={{ cursor: 'pointer' }}  // Add pointer cursor to indicate clickability
                            >
                              {recording ? (
                                <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
                              ) : (
                                <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
                              )}
                              <p className="mt-2">9</p>
                            </div>

                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
                      <div style={{ cursor: "pointer" }} onClick={handleNext}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/626/626043.png"
                          alt="next"
                          className="img-fluid"
                          style={{
                            width: "23px",
                            height: "23px",
                            objectFit: "cover",
                          }}
                        />
                        <p className="mt-2">0</p>
                      </div>
                    </div>
                    <div className="text-center">
              <button style={{background: 'linear-gradient(to right, rgb(0, 201, 255), rgb(146, 140, 229))', border: 'none', borderRadius: '25px', color: 'white', fontSize: '14px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px', cursor: 'pointer'}}>AI Generate</button>
              <div style={styles.spanContainer}>
                <span  style={styles.span}>7</span>
              </div>
            </div>
                  </div>
                </>
              )}
            </div>

          </div>
        );
    }
  };

  return (
    <>
      <div className="container-fluid">
      <div style={{textAlign:'center'}}>
      <AdBanner/>
      </div>
        <div>
          <div className="col-6 chatitem">
              {messages.map((msg, index) => {
                const userName = getStringBeforeAtSymbol(extractLastPathPart(msg.from));
                const alignStyle = CurrentUser === extractLastPathPart(msg.from) ? 'end' : 'left';
                const marginRStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
                const marginTStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
                const isGif = msg.message.endsWith('.gif');
                const isPng = msg.message.endsWith('.png');
                const isWav = msg.message.endsWith('.wav');

                if (isGif) {
                  return null;
                }
                if (isPng) {
                  return (
                    <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle , padding:'10px 0' }}>
                      <img src={`https://watchparty.tpvai.com/b${msg.message}`} alt="png" width="75" height="75" />
                      <span className="autherusername">{userName}</span>
                    </div>
                  );
                }
                
                if (isWav) {
                  return (
                    <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
                       {/* <audio controls ref={(el) => (audioRefs.current[index] = el)} src={msg.message} /> */}
                       <audio
                        controls
                        ref={(el) => (audioRefs.current[index] = el)} // Store the ref to each audio element
                        src={msg.message}
                        onPlay={() => playAudio(index)} // Trigger playAudio when playing
                        onPause={() => stopAudio(index)} // Trigger stopAudio when pausing
                      />
                      <span className="autherusername">{userName}</span>
                    </div>
                  );
                }
              })}

          </div>
        </div>
        <div className="row">
          <div className="col-6 text-sm-end">
          </div>
        </div>
      </div>

      <footer className="fixed-bottom p-3">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-auto">
              {renderAiPage()}
              {/* {currentAiPage !== 'main' && (
                <div className="navigation-buttons">
                  <button 
                    onClick={handleAiPrev} 
                    disabled={currentAiPage === 1}
                    className="nav-btn"
                  >
                    Previous
                  </button>
                  <span className="page-indicator">{currentAiPage} / 4</span>
                  <button 
                    onClick={handleAiNext} 
                    disabled={currentAiPage === 4}
                    className="nav-btn"
                  >
                    Next
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};


export default ChatScreen;


