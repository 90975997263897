import React, { useEffect } from 'react';

const Page2 = ({ setCurrentAiPage }) => {
    const Cpage = 2
  const handleBackClick = () => {
    console.log("Back button clicked");
    setCurrentAiPage("main");
  };

  const handlePreClick = () => {
    console.log("PRE button clicked");
    setCurrentAiPage(2);
  };

  const handleOwnClick = () => {
    console.log("OWN button clicked");
    setCurrentAiPage(3);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      // Check if the key pressed is '1' or '2' and call corresponding function
      if (e.key === "1") {
        handlePreClick();
      } else if (e.key === "2") {
        handleOwnClick();
      }
      else if (e.key === "5") {
        handleBackClick();
      }
    };

    // Add the event listener on component mount
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      padding:"20px",
      fontFamily: "Arial, sans-serif",
      color: "#333"
    }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          cursor: "pointer",
          fontSize: "14px",
          color: "#007bff",
          transition: "color 0.2s"
        }}
        onClick={handleBackClick}
        onMouseOver={(e) => (e.target.style.color = "#0056b3")}
        onMouseOut={(e) => (e.target.style.color = "#007bff")}
      >
        Home
        <p style={{
          margin: "0",
          fontSize: "12px",
          textAlign: "center",
          color: "#333"
        }}>5</p>
      </div>
      <div style={{
        display: "flex",
        gap: "20px",
        marginTop: "40px"
      }}>
        <div>
        <button
          onClick={handlePreClick}
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "8px",
            backgroundColor: "#007bff",
            color: "white",
            fontSize: "14px",
            fontWeight: "bold",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.3s, transform 0.2s",
            cursor: "pointer",
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0056b3";
            e.target.style.transform = "scale(1.05)";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#007bff";
            e.target.style.transform = "scale(1)";
          }}
        >
          PRE
        </button>
        <p style={{textAlign:'center'}}>1</p>
        </div>

        <div>
        <button
          onClick={handleOwnClick}
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "8px",
            backgroundColor: "#007bff",
            color: "white",
            fontSize: "14px",
            fontWeight: "bold",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.3s, transform 0.2s",
            cursor: "pointer",
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0056b3";
            e.target.style.transform = "scale(1.05)";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#007bff";
            e.target.style.transform = "scale(1)";
          }}
        >
          OWN
        </button>
        <p style={{textAlign:'center'}}>2</p>
        </div>

      </div>
    </div>
  );
};

export default Page2;
