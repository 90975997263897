import React, { useRef, useEffect, useState } from "react";

const Page4 = ({ setCurrentAiPage , onGenerate }) => {
  const textareaRef = useRef(null);
  const Cpage = 4;
  const [transcript, setTranscript] = useState('hello');
  const [isListening, setIsListening] = useState(false);
  const [lang, setLang] = useState("en-US");
  const [error, setError] = useState(null);
  let recognition;

  useEffect(() => {
    if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
      setError('Speech recognition is not supported in this browser.');
      return;
    }

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = lang;

    recognition.onresult = (event) => {
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        finalTranscript += event.results[i][0].transcript;
      }
      setTranscript(finalTranscript);
    };

    recognition.onerror = (event) => {
      setError(`Error occurred in speech recognition: ${event.error}`);
    };

    if (isListening) recognition.start();
    else recognition.stop();

    return () => recognition && recognition.stop();
  }, [isListening]);

  const handleStart = () => {
    setError(null);
    setIsListening(true);
  };

  const handleStop = () => {
    setIsListening(false);
  };

  const handleBackClick = () => {
    console.log("Back button clicked");
    setCurrentAiPage("main");
  };

//   const handleGenerateClick = () => {
//     console.log("Generate button clicked");
//     setPage(5);
//   };
const handleGenerateClick = () => {
    if (transcript.trim()) {
      onGenerate(transcript);
      // setCurrentAiPage(4);
    } else {
      alert('Please record some text first');
    }
  };

  const handleInput = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "3") {
        if (isListening) {
          handleStop();
        } else {
          handleStart();
        }
      } else if (e.key === "5") {
        handleBackClick();
      }
      else if (e.key === "2") {
        handleGenerateClick();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isListening]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px", fontFamily: "Arial, sans-serif" }}>
      <div style={{
        position: "absolute",
        top: "10px",
        left: "10px",
        cursor: "pointer",
        fontSize: "14px",
        color: "#007bff",
        display: "flex",
        alignItems: "center",
        transition: "color 0.2s"
      }} onClick={handleBackClick}
        onMouseOver={(e) => (e.target.style.color = "#0056b3")}
        onMouseOut={(e) => (e.target.style.color = "#007bff")}>
        Home
        <p style={{
          margin: "0",
          fontSize: "12px",
          textAlign: "center",
          color: "#333",
          marginLeft: "5px"
        }}>5</p>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "40px" }}>
        <button
          onClick={isListening ? handleStop : handleStart}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: isListening ? '#dc3545' : '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginBottom: '15px',
            transition: "background-color 0.3s, transform 0.2s"
          }}
          onMouseOver={(e) => e.target.style.transform = "scale(1.05)"}
          onMouseOut={(e) => e.target.style.transform = "scale(1)"}
        >
          {isListening ? 'Stop Recording' : 'Start Recording'}
        </button>
        <p style={{ margin: "5px 0 0 0", fontSize: "14px", textAlign: "center" }}>Record Start/Stop</p>
        <p style={{ margin: "0", fontSize: "12px", textAlign: "center" }}>3</p>
      </div>

      <div style={{ marginTop: "20px", width: "80%", textAlign: "center" }}>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <textarea
          ref={textareaRef}
          placeholder="Record Your Prompts"
          onInput={handleInput}
          value={transcript}
          style={{
            width: "100%",
            minHeight: "90px",
            textAlign: "center",
            border: "2px solid #007bff",
            borderRadius: "8px",
            fontSize: "16px",
            padding: "10px",
            resize: "none",
            overflow: "hidden",
            transition: "border-color 0.3s"
          }}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <button
          onClick={handleGenerateClick}
          style={{
            padding: "10px 20px",
            border: "2px solid #007bff",
            borderRadius: "8px",
            fontSize: "14px",
            backgroundColor: "#007bff",
            color: "white",
            cursor: "pointer",
            transition: "background-color 0.3s, transform 0.2s"
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
          onMouseOut={(e) => e.target.style.backgroundColor = "#007bff"}
        >
          Generate
        </button>
        <p style={{ marginTop: "5px", fontSize: "12px", textAlign: "center" }}>2</p>
      </div>
    </div>
  );
};

export default Page4;
