import React, { useEffect } from 'react';

const Page3 = ({ setCurrentAiPage , onGenerate }) => {
  // const navigate = useNavigate();
  const Cpage = 3
  const handleBackClick = () => {
    console.log("Back button clicked");
    setCurrentAiPage("main");
  };

  const handleGenerateClick = () => {
    console.log("Generate button clicked");
    setCurrentAiPage(4);
    const ownprompt = "Create a set of stickers featuring a cute, expressive cartoon cat character in different emotions: happy, sad, excited, and surprised. Each sticker should have bold outlines, vibrant colors, and playful details. The background should be transparent, so only the character is visible."
    onGenerate(ownprompt);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      // Check if the key pressed is '1' or '2' and call corresponding function
      if (e.key === "1") {
        handleGenerateClick();
      }
      else if (e.key === "5") {
        handleBackClick();
      }
    };

    // Add the event listener on component mount
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      fontFamily: "Arial, sans-serif",
      color: "#333",
      padding:'13px'

    }}>
      {/* Back button at the top left */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          cursor: "pointer",
          fontSize: "14px",
          color: "#007bff",
          display: "flex",
          alignItems: "center",
          transition: "color 0.2s",
          
        }}
        onClick={handleBackClick}
        onMouseOver={(e) => (e.target.style.color = "#0056b3")}
        onMouseOut={(e) => (e.target.style.color = "#007bff")}
      >
        {/* <FaArrowLeft size={20} style={{ marginRight: "5px" }} /> */}
        Home
        <p style={{
          margin: "0",
          fontSize: "12px",
          textAlign: "center",
          color: "#333",
          marginLeft: "5px",
        }}>5</p>
      </div>

      {/* Generate button in the center */}
      <div style={{ marginTop: "100px", textAlign: "center" }}>
      <button
          onClick={handleGenerateClick}
          style={{
            padding: "10px 40px",
            border: "none",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "bold",
            backgroundColor: "#007bff",  // Consistent blue background
            color: "white",
            cursor: "pointer",
            transition: "transform 0.2s", // Only apply scaling transition, not color
          }}
          onMouseOver={(e) => {
            e.target.style.transform = "scale(1.05)";
          }}
          onMouseOut={(e) => {
            e.target.style.transform = "scale(1)";
          }}
        >
          Generate
        </button>
        <p style={{ marginTop: "5px", fontSize: "14px", color: "#555" }}>1</p>
      </div>
    </div>
  );
};

export default Page3;
