// import React, { useEffect} from "react";

// const Page5 = ({ setCurrentAiPage, generatedImage, isLoading , sendMessage }) => {
//   const handleBackClick = () => {
//     setCurrentAiPage("main");
//   };

//   const handleGenerateClick = (generatedImage) => {
//     console.log("i have data->",generatedImage)
//     // alert("Stickers Sent->",generatedImage);
//     sendMessage(generatedImage)
//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       if (e.key === "2") {
//         handleGenerateClick(generatedImage);
//       } else if (e.key === "5") {
//         handleBackClick();
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, []);

//   console.log("AI Image:-",generatedImage)

//   return (
//     <div style={{
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       marginTop: "20px",
//       fontFamily: "Arial, sans-serif",
//       color: "#333"
//     }}>
//       <div
//         style={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           cursor: "pointer",
//           fontSize: "14px",
//           color: "#007bff",
//           display: "flex",
//           alignItems: "center",
//           transition: "color 0.2s",
//         }}
//         onClick={handleBackClick}
//       >
//         Home
//         <p style={{
//           margin: "0",
//           fontSize: "12px",
//           textAlign: "center",
//           marginLeft: "5px"
//         }}>5</p>
//       </div>

//       <div style={{
//         marginTop: "20px",
//         width: "80%",
//         textAlign: "center",
//       }}>
//         {isLoading ? (
//           <div style={{ padding: "20px" }}>Generating image...</div>
//         ) : (
//           <img
//             src={generatedImage || "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=1200&s=1"}
//             alt="Generated"
//             style={{
//               width: "100%",
//               height: "170px",
//               objectFit: "cover",
//               border: "2px solid #007bff",
//               borderRadius: "8px",
//               transition: "transform 0.3s ease, box-shadow 0.3s ease",
//             }}
//             onMouseOver={(e) => {
//               e.target.style.transform = "scale(1.05)";
//               e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)";
//             }}
//             onMouseOut={(e) => {
//               e.target.style.transform = "scale(1)";
//               e.target.style.boxShadow = "none";
//             }}
//           />
//         )}
//       </div>

//       <div style={{ marginTop: "20px" }}>
//         <button
//           onClick={() => handleGenerateClick(generatedImage)}
//           style={{
//             padding: "10px 20px",
//             border: "2px solid #007bff",
//             borderRadius: "8px",
//             fontSize: "14px",
//             backgroundColor: "#007bff",
//             color: "white",
//             cursor: "pointer",
//             transition: "background-color 0.3s, transform 0.2s",
//           }}
//           onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
//           onMouseOut={(e) => e.target.style.backgroundColor = "#007bff"}
//         >
//           Send
//         </button>
//         <p style={{
//           marginTop: "5px",
//           fontSize: "12px",
//           textAlign: "center",
//           color: "#555",
//         }}>2</p>
//       </div>
//     </div>
//   );
// };

// export default Page5;


// key handling
import React, { useEffect } from "react";

const Page5 = ({ setCurrentAiPage, generatedImage, isLoading, sendMessage }) => {
  const handleBackClick = () => {
    setCurrentAiPage("main");
  };

  const handleGenerateClick = (generatedImage) => {
    console.log("i have data->", generatedImage);
    sendMessage(generatedImage); // This triggers your send action
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "2") {
        handleGenerateClick(generatedImage); // Triggering on `2`
      } else if (e.key === "5") {
        handleBackClick(); // Triggering on `5`
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [generatedImage]); // Adding `generatedImage` as a dependency

  console.log("AI Image:-", generatedImage);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        fontFamily: "Arial, sans-serif",
        color: "#333",
        padding: "0px 23px"
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          cursor: "pointer",
          fontSize: "14px",
          color: "#007bff",
          display: "flex",
          alignItems: "center",
          transition: "color 0.2s",
        }}
        onClick={handleBackClick}
      >
        Home
        <p
          style={{
            margin: "0",
            fontSize: "12px",
            textAlign: "center",
            marginLeft: "5px",
          }}
        >
          5
        </p>
      </div>

      <div
        style={{
          marginTop: "20px",
          width: "80%",
          textAlign: "center",
        }}
      >
        {isLoading ? (
          <div style={{ padding: "20px" }}>Generating image...</div>
        ) : (
          <img
            src={
              generatedImage ||
              "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=1200&s=1"
            }
            alt="Generated"
            style={{
              width: "100%",
              height: "111px",
              objectFit: "cover",
              border: "2px solid #007bff",
              borderRadius: "8px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.target.style.transform = "scale(1.05)";
              e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)";
            }}
            onMouseOut={(e) => {
              e.target.style.transform = "scale(1)";
              e.target.style.boxShadow = "none";
            }}
          />
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        <button
          onClick={() => handleGenerateClick(generatedImage)}
          style={{
            padding: "10px 20px",
            border: "2px solid #007bff",
            borderRadius: "8px",
            fontSize: "14px",
            backgroundColor: "#007bff",
            color: "white",
            cursor: "pointer",
            transition: "background-color 0.3s, transform 0.2s",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
        >
          Send
        </button>
        <p
          style={{
            marginTop: "5px",
            fontSize: "12px",
            textAlign: "center",
            color: "#555",
          }}
        >
          2
        </p>
      </div>
    </div>
  );
};

export default Page5;
