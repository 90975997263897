import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../utils/TranslationContext';

function EnterEmail() {
  const history = useNavigate();

  const inputRef = useRef(null);
  const [outputText, setOutputText] = useState("");
  const [emailId, setEmailId] = useState("Guest");
  const [AddemailId, setAddEmailId] = useState("");
  const loginButtonRef = useRef(null);


  const { t } = useTranslation();


  useEffect(() => {
    inputRef.current.focus();

    const storedEmail = localStorage.getItem("userId");
    if (storedEmail) {
      const username = storedEmail.split("@")[0];
      setEmailId(username);
      setAddEmailId(username);
    }
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      inputRef.current.focus();
      saveEmailToLocalStorage();
    }
  };

  const handleChange = (event) => {
    const email = event.target.value;
    setEmailId(email);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      const enteredText = event.target.value;
      setOutputText(enteredText);
      setEmailId(enteredText);
      event.target.value = "";
    } else if (event.key === "Enter") {
      loginButtonRef.current.click();
      console.log("Hit Enter");
    }
  };

  const saveEmailToLocalStorage = () => {
    console.log("emailId", emailId);
    // let HashEmailid = emailId + "@" + "tv.com";
    let HashEmailid = emailId + "@" + Math.floor(10000 + Math.random() * 90000);
    localStorage.setItem("userId", HashEmailid);
    history("/welcome");
  };

  return (
    <div className="container" onKeyDown={handleKeyDown}>
      <div className="row min-vh-100 justify-content-center">
        <div className="col-12 text-center"></div>
        <div
          className="col-md-8 col-lg-6 col-xl-4 text-center"
          style={{ marginTop: "-5%", marginBottom: "3%" }}
        >
          {/* <h5>Welcome to Watchparty</h5> */}
          <div className="card">
            {AddemailId ? (
              <div>
                {t('Hi')} <span style={{ fontWeight: "bold" }}>{emailId}</span>,
                {t('Welcome to WatchRoom')}.
              </div>
            ) : (
              <div style={{ fontSize: "15px" }}>
                {t('Please input your name to chat with me.')}
              </div>
            )}
            <div className="card-body text-center">
              <input
                ref={inputRef}
                type="text"
                placeholder="Enter Your Name"
                style={{
                  width: "100%",
                  padding: "8px",
                  boxSizing: "border-box",
                  outline: "none",
                  border: "none",
                }}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
                value={emailId}
              />
              {/* <button ref={loginButtonRef} class="button-24" onClick={saveEmailToLocalStorage}>Save</button> */}
              {AddemailId ? (
                <button className="button-24" onClick={saveEmailToLocalStorage}>
                  {t('Update')}
                </button>
              ) : (
                <button className="button-24" onClick={saveEmailToLocalStorage}>
                  {t('Save')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterEmail;
