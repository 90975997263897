import React, { createContext, useState, useContext, useEffect } from 'react';
import enTranslations from '../locales/en.json';
import zhCNTranslations from '../locales/zh-CN.json';
import hiTranslations from '../locales/hi.json';
import deTranslations from '../locales/de.json';

const TranslationContext = createContext();

const detectPlatform = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  console.log("userAgent--OS-->", userAgent);
  const isAndroid = /android/.test(userAgent);
  const isLinux = /linux/.test(userAgent) && !/android/.test(userAgent);
  const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

  if (isAndroid) {
    return 'Android';
  } else if (isLinux) {
    return 'Linux';
  } else if (isBrowser) {
    return 'Browser';
  } else {
    return 'Unknown platform';
  }
};

function getLanguageCodeLinux() {
  window.parent.postMessage('getPlatformLanguage', '*');
}

const platform = detectPlatform();

export const TranslationProvider = ({ children }) => {
  const [linuxTv, setlinuxTv] = useState(null);
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    function handleMessage(event) {
      if (event.data.type === 'platformLanguage') {
        const encodedTvLang = event.data.result;
        setlinuxTv(encodedTvLang);
      }
    }
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const getInitialLanguage = () => {
      let initialLanguage;

      if (platform === 'Android') {
        const javaLanguageCode = window.JavaFun?.getLanguageCode?.();
        if (javaLanguageCode) {
          initialLanguage = javaLanguageCode;
        }
      } else if (platform === 'Linux') {
        try {
          if (window.JavaFun.getPlatformEnvironment() === "android") {
            const javaLanguageCode = window.JavaFun?.getLanguageCode?.();
            if (javaLanguageCode) {
              initialLanguage = javaLanguageCode;
            }
          } else {
            console.log("Trigger Linux Function");
            getLanguageCodeLinux();
            return; 
          }
        } catch (error) {
          console.error("Error in Linux platform logic:", error);
          if (linuxTv) {
            initialLanguage = linuxTv;
            console.log("javaLanguageCode->", linuxTv);
          } else {
            getLanguageCodeLinux();
            return;
          }
        }
      } else if (platform === 'Browser') {
        console.log("Running on Browser");
      } else {
        console.log("Unknown platform");
      }

      if (!initialLanguage) {
        const storedLanguage = localStorage.getItem("language");
        initialLanguage = storedLanguage ? storedLanguage : "en";
      }

      const supportedLanguages = ["en", "zh", "hi", "de"];
      setLanguage(supportedLanguages.includes(initialLanguage) ? initialLanguage : "en");
    };

    getInitialLanguage();
  }, [linuxTv]);

  useEffect(() => {
    if (language) {
      localStorage.setItem("language", language);
    }
  }, [language]);

  const translations = {
    en: enTranslations,
    zh: zhCNTranslations,
    hi: hiTranslations,
    de: deTranslations,
  };

  const t = (key) => translations[language]?.[key] || key;

  if (!language) {
    return null; 
  }

  return (
    <TranslationContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);