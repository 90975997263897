import React, { useEffect, useState } from "react";
import * as QRCode from "qrcode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { encodeString} from '../utils/encryptionUtil';
import { useTranslation } from '../utils/TranslationContext';
import AdBanner from "./ads/AdBanner";
import VideoAdBanner from "./ads/VideoAdBanner";
const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};


function extractSingleWord(tag) {
  switch(tag) {
      case "GENRE_MOVIE":
          return "movie";
      case "GENRE_SPORTS":
          return "sports";
      default:
          return "other";
  }
}


function generateUserID(prefix, length) {
  const result = prefix + Math.floor(Math.random() * Math.pow(10, length));
  return result;
}

function deleteLocalStorageItem(key) {
  localStorage.removeItem(key);
}


export default function Qrcodezone({ socket }) {
  const { t } = useTranslation();
  let tvchannelCall  = ''
  let tvProgramInfoCall  = ''
  const history = useNavigate();
  console.log("socket Qrcodezone", socket);
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState();
  const [shortURL, setShortURL] = useState("");
  const [tvConnectionData, setTVConnectionData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [QrURL, setQRURL] = useState("");
  const [TVCall, setTVCall] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [Programinfo, setProgramInfo] = useState("")
  const serverUrl = process.env.REACT_APP_WEB_URL;

  const conv = "/#/" + generateRandomString(6);

  function detectPlatform() {
    const userAgent = navigator.userAgent.toLowerCase();
  console.log("userAgent--OS-->",userAgent)
    const isAndroid = /android/.test(userAgent);
    const isLinux = /linux/.test(userAgent) && !/android/.test(userAgent);
    const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
  
    if (isAndroid) {
      return 'Android';
    } else if (isLinux) {
      return 'Linux';
    } else if (isBrowser) {
      return 'Browser';
    } else {
      return 'Unknown platform';
    }
  }

  function callgetEnvironmentByLinuxFun() {
    window.parent.postMessage('getEnvironmentByLinux', '*')
  }

  function callgetChannelTripletDetailsFun() {
    window.parent.postMessage('getChannelTripletDetails', '*')
  }


  function callParentFunction() {
    window.parent.postMessage('getChannelTripletDetails', '*');
    window.parent.postMessage('getEnvironmentByLinux', '*');
  }

  useEffect(() => {
    const platform = detectPlatform();
    if (platform === 'Android') {
      let tvcall = window.JavaFun.getChannelTripletDetails();
      setTVCall(tvcall);
    } else if (platform === 'Linux') {
      try {
        if (window.JavaFun.getPlatformEnvironment() == "android") {
          const tvcall = window.JavaFun.getChannelTripletDetails();
          const tvcinfos = window.JavaFun.getChannelProgramInfo();
          let tvProgramInfocall = tvcinfos.split('genre: ')[1].split('\n')[0].trim();
          const encodedTvcall = encodeString(tvcall);
          setTVCall(encodedTvcall);
          setProgramInfo(tvProgramInfocall);
          tvchannelCall = encodedTvcall;
          tvProgramInfoCall= tvProgramInfocall
        } else {
          console.log("Trigger Linux Function");
        }
      } catch (error) {
        console.error("Error in Linux platform logic:", error);
        callParentFunction();
      }
      console.log("Running on Linux");
    } else if (platform === 'Browser') {
      const tvcall = "browser";
      setTVCall(tvcall);
      tvchannelCall = tvcall;
      const tvcinfos = `"_id: 5761
      eventid: 6744
      presetid: 17
      eventname: NCIS
      version: 0
      starttime: 1670244600
      endtime: 1670248800
      shortinfo: null
      extendedinfo: Le tueur de port en port frappe à nouveau, s'attaquant cette fois à Danny Price, l'ancien coéquipier de DiNozzo lorsqu'il était à la brigade criminelle de Baltimore. Ce dernier se remémore alors son passé, notamment sa première rencontre avec Gibbs.
      languageid: 27
      linktype: null
      targetindex: null
      genre: GENRE_MOVIE
      scrambled: 0
      rating: null/null/null
      guidanceinfo: null
      videoformat: 2"`;
      let genre = tvcinfos.split('genre: ')[1].split('\n')[0].trim();
      const tvcinfo = genre
      console.log("tvcinfo type->",typeof tvcinfo)
      console.log("tvcinfo type->",typeof tvcinfo)
      console.log("tvcinfo type-> dddddd",typeof extractSingleWord(tvcinfo))
      setProgramInfo(tvcinfo)
      tvProgramInfoCall = tvcinfo;

    } else {
      console.log("Unknown platform");
    }
    
    function handleMessage(event) {
      if (event.data.type === 'linuxChannelDetail') {
        console.log("____________________1_______________Received linuxChannelDetail event:", event);
        const encodedTvcall = encodeString(event.data.result);
        console.log("++++++++++++event.data.result+++++++++++++>",event.data.result)
        setTVCall(encodedTvcall);
        tvchannelCall = encodedTvcall;
        tvProgramInfoCall = "defult";
      }

      if (event.data.type === 'platformEnvironment') {
        console.log("______________2____________________Received platformEnvironment event:", event);
        if (event.data.result === "LINUX_TV") {
          console.log("______________3__________________Running on LINUX_TV environment");
        }
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  


  const Joinedurl = serverUrl + conv;
  useEffect(() => {
    const generateQRCode = (token, sessionId,TVCall) => {
      localStorage.setItem("UserRoomId", token);
      const url = `${serverUrl}/#/joinscan?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&programInfo=${Programinfo}&channelId=${TVCall}`;
      console.log("______________11____inside useffect________________",url)
      setQRURL(url);
      const url_socket = `${serverUrl}/joinscan?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&programInfo=${Programinfo}&channelId=${TVCall}`;
      const body = { longURL: url };
      deleteLocalStorageItem('alreadyJoined');
      console.log("body", body);
      axios
        .post("https://watchparty.tpvai.com/b/shorten", body)
        .then((response) => {
          const shortURL = response.data.shortURL;
          console.log("shortURL", shortURL);
          setShortURL(shortURL);
        })
        .catch((error) => {
          console.error("Failed to fetch shortURL:", error);
        });

      QRCode.toDataURL(url, (err, qrCodeUrl) => {
        if (err) {
          console.error("Error generating QR code:", err);
          return;
        }
        setQrCodeImageUrl(qrCodeUrl);
        console.log("qrcodeurlllllllll", qrCodeUrl);
        socket.emit("qrCodeScanned", { data: url_socket });
      });
    };

    const generateTokenAndSessionId = () => {
      const token = generateRandomString(10);
      const sessionId = generateRandomString(10);
      return { token, sessionId };
    };

    const { token, sessionId } = generateTokenAndSessionId();
    console.log("token", token);
    console.log("Session Token:-", sessionId);
    
    generateQRCode(token, sessionId, TVCall);

    const urlParams = new URLSearchParams(window.location.search);
    const scannedToken = urlParams.get("token");
    const scannedSessionId = urlParams.get("sessionId");

    if (scannedToken && scannedSessionId) {
    }
    if (TVCall) {
      const { token, sessionId } = generateTokenAndSessionId();
      generateQRCode(token, sessionId, TVCall);
      setIsLoading(false);
    }
  }, [TVCall,socket]);

  console.log("________________________9___________________QrURL_______________",QrURL)


  socket.on("TVconnected", function (response) {
    console.log("SocketMessageReceiver", response);
    const storedEmail = localStorage.getItem("userId");
    const joinedemail = storedEmail;
    localStorage.setItem("room", response.data.host);
    localStorage.setItem("ChannelId", JSON.stringify(response.data.channelId));
    localStorage.setItem("ProgramInfo", JSON.stringify(response.data.GetprogramInfo));
    localStorage.setItem("email", joinedemail);
    setTVConnectionData(response.data);
    setShowMessage(true);
    var alreadyJoined = localStorage.getItem("alreadyJoined");
    if (alreadyJoined === "true") {
        console.log("User has already joined.");
    } else {
        console.log("User has not joined yet or the key does not exist.");
        history("/letchat");
    }
  });

  return (
    <>
      <div className="container">
        <div className="row min-vh-100 justify-content-center">
          <div className="col-12 text-center"></div>
          <div
            className="col-md-8 col-lg-6 col-xl-4"
            style={{ marginTop: "-5%", marginBottom: "3%" }}
          >
            <div className="card">
              <div className="card-body text-center">
              <AdBanner/>
                <h5 className="card-text">{t('Create/Join WatchRoom')}</h5>
                <div style={{ fontWeight: "bold", fontSize: "11px" }}>
                  <p>{t('Follow these steps')}:</p>
                  <ol style={{ textAlign: "justify" }}>
                    <li>{t('Open any scanner app like Google Lens')}.</li>
                    <li>{t('Scan the QR code')}.</li>
                    <li>
                    {t('Share the resulting link with your buddies using SMS')},
                    {t('WhatsApp, etc')}.
                    </li>
                  </ol>
                </div>

                {isLoading ? (
                <div className="loadercenter">
                  <span className="spinner"></span>
                </div>
              ) : (
                <>
                <div style={{position:'absolute',right:'0'}}>
                <VideoAdBanner/>
                </div>
                <img src={qrCodeImageUrl} alt="qrcode" width="95" height="95" />
          
                </>
       
                
              )}
                <h6 className="card-text">{t('Share this QR CODE')}</h6>
                <h6 className="card-text">{t('QR')}</h6>
                <a>
                  <h6 className="card-text">{t('Join Via Url')}:</h6>
                  <span style={{ color: "blue", fontSize: "11px" }}>
                    {shortURL}
                  </span>
                </a>
                <div style={{ fontWeight: "bold", fontSize: "11px" }}>
                  <p>
                  {t('Alternatively, share the above link with your friends to')}<br/> 
                  {t('join the watch room')}
                  </p>


                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
